import { PortableTextTypeComponentProps } from "@portabletext/react";
import { config } from "config";
import useUser from "hooks/useUser";
import { useRouter } from "next/router";
type PlaceholderType = "allowed-concurrent-login";
type PlaceholderProps = PortableTextTypeComponentProps<{
  name: PlaceholderType;
}>;
export default function Placeholder({
  value
}: PlaceholderProps) {
  const router = useRouter();
  const {
    user
  } = useUser();
  if (value.name === "allowed-concurrent-login") {
    const allowed = router.query.allowed ?? config.auth.defaultMaxConcurrentLogins.toString();
    return <strong>{allowed}</strong>;
  }
  if (value.name === "user-name" && user) {
    return <span>{user.name}</span>;
  }
  return null;
}