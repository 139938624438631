import { config } from "config"
import useUser from "hooks/useUser"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    IProfile,
    getProfile,
    updateProfile
} from "data/customerManager/api/profile"
import { ProfileFormType } from "data/customerManager/api/profile"
import isEnabled from "lib/is-enabled"

enum ProfileCacheKeys {
    PROFILE = "profile"
}

/**
 * Get profile of the authenticated user
 * @param options React Query options
 * @returns Profile of the authenticated user
 */

export function useGetProfile(options?: OptionsType<IProfile | null>) {
    const { authenticated, accessToken, isFetching, user } = useUser()

    const enabled =
        isEnabled(authenticated && !isFetching, options) &&
        config.features.enableConfirmUserDetails

    return useQuery<IProfile | null, Error>(
        [ProfileCacheKeys.PROFILE, user?.guid],
        async () => await getProfile(accessToken),
        {
            ...options,
            enabled
        }
    )
}

/**
 * Update profile of the authenticated user
 * @returns Mutation function to update profile
 */

export function useUpdateProfile() {
    const queryClient = useQueryClient()
    const { accessToken } = useUser()

    return useMutation(
        (profile: ProfileFormType) => updateProfile(profile, accessToken),
        {
            onMutate: async profile => {
                // Cancel outgoing queries
                await queryClient.cancelQueries([ProfileCacheKeys.PROFILE])

                // Snapshot previous data
                const previousData = queryClient.getQueryData<IProfile>([
                    ProfileCacheKeys.PROFILE
                ])
                // Optimistic update
                if (previousData) {
                    queryClient.setQueryData([ProfileCacheKeys.PROFILE], {
                        ...profile
                    })
                }
                return { previousData: profile }
            },
            onError: (_, __, context: any) => {
                // revert if error
                if (context?.previousData) {
                    queryClient.setQueryData(
                        [ProfileCacheKeys.PROFILE],
                        context.previousData
                    )
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries(ProfileCacheKeys.PROFILE)
            }
        }
    )
}
